export default {



}


<style scoped>
    @import "../assets/victory.css"
</style>

<template>
    <div class="fireworks"></div>

</template>